<template>
  <div
      :class="{'pt-4': readOnly}"
      class="px-4 pb-4 timecard-entries">
    <AgDataTable
        :actions="readOnly ? '' : 'add'"
        domLayout="autoHeight"
        :read-only="readOnly"
        :pagination="false"
        :default-filters="false"
        :compact="true"
        :data="data"
        :columns="columns"
        :add-text="$t('Add Entry')"
        :enableRangeSelection="true"
        :addRowOnTab="true"
        :get-empty-row="getEmptyRow"
        :authorize-to-copy-last-row="true"
        :get-row-id="getRowId"
        :showCellsLegend="!readOnly"
        v-bind="extraGroupParams"
        suppressColumnReordering
        @add="onAddRow"
        @cell-focused="onCellFocused"
        @cell-value-changed="onCellValueChanged"
        @grid-ready="onGridReady"
    >
      <template #additional-actions>
        <TableSyncButton
            :loading="updatingEntries"
            class="mr-2"
            @click="updateAllEntries"
        />
      </template>
      <template #date="{row}">
        <TimecardEntryDate :row="row" :days="params.days"/>
      </template>
      <template #overrides="{row}">
        <div class="flex justify-center w-full">
          <TableEditButton :skipFocus="true"/>
        </div>
      </template>
      <template #view_actions="{row}">
        <div v-if="row?.id" class="flex justify-center w-full">
          <TableViewButton @click="viewSummary(row)" :skipFocus="true"/>
        </div>
      </template>
      <template #row_actions="{row}">
        <div
          v-if="!row?.timesheet_entry_id"
          class="flex justify-center w-full">
          <TableDeleteButton :skipFocus="true"/>
        </div>
      </template>
    </AgDataTable>
    <div class="w-full mt-4 grid grid-cols-2">
      <div>
        <div tabindex="0">
          <!--Temporary hack to avoid focusing on check type after tabbing on last grid row to add an entry-->
        </div>
        <TimecardEntriesForm
            v-if="!readOnly"
            :time-card="params.data"
            @save="params.onUpdateTimeCard($event, params)"/>
      </div>
      <OverrideSummary
          :fixed="readOnly"
          :data="lastSelectedRow"
          :employee="params.data.employee"
          :days="params.days"
          @close="lastSelectedRow = null"
      />
      <TimecardShortSummary
        v-if="!readOnly && lastSelectedShortRow"
        :data="params.data"
        :days="params.days"
        :employee="params.data.employee"
        @close="lastSelectedShortRow = null"
      />
    </div>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { setTypeSources } from "@/components/grid-table/utils/cost-center";
import {
  getCostCenterRowDefaults,
  getEmptyEntry,
  getTotals,
  isRowValid,
  RateFrom,
} from "@/modules/payroll/utils/timeCardUtils";
import TimeCardEntryOverridesDialog from "@/modules/payroll/components/timecard/TimeCardEntryOverridesDialog";
import TimeCardEntryDeleteDialog from "@/modules/payroll/components/timecard/TimeCardEntryDeleteDialog";
import { cellClasses, requiredValueSetter, stopEditingOnTab } from "@/components/ag-grid/columnUtils";
import { dateTypes } from "@/plugins/dateFormatPlugin";
import OverrideSummary from "@/modules/payroll/components/timecard/OverrideSummary";
import { costCenterFields, hourFields } from "@/modules/common/util/costCenterUtils";
import { codeTypes } from "@/modules/payroll/components/rates/util";
import axios from "axios";
import { getSpecialCodeTypeLabel } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import TimecardEntriesForm from "@/modules/payroll/components/timecard/TimecardEntriesForm";
import {
  accountCol,
  additionalSourceCol,
  costCenterCol,
  sourceCol,
  typeCol,
  updateCostCenterHeaderNames
} from "@/components/ag-grid/columns/costCenterColumns";
import { getSpecialSourceOption } from "@/components/grid-table/utils/cost-center-cell";
import { CornerUpLeftIcon, ClockIcon } from 'vue-feather-icons'
import { isAuthorized } from "@/plugins/actionAuthorization";
import TimecardEntryGroupRow from "@/modules/payroll/components/timecard/TimecardEntryGroupRow.vue";
import bus from '@/event-bus/EventBus';
import TableSyncButton from "@/components/table/actions/TableSyncButton.vue";
import TimecardShortSummary from "@/modules/payroll/components/timecard/TimecardShortSummary.vue";
import TimecardEntryDate from "@/modules/payroll/components/timecard/TimecardEntryDate.vue";

export default {
    components: {
      TimecardEntryDate,
      TimecardShortSummary,
      TableSyncButton,
      OverrideSummary,
      TimeCardEntryDeleteDialog,
      TimeCardEntryOverridesDialog,
      TimecardEntriesForm,
      CornerUpLeftIcon,
      ClockIcon,
      TimecardEntryGroupRow,
    },
    data() {
      return {
        data: this.params?.data?.entries || [],
        grid: null,
        lastSelectedRow: null,
        lastSelectedShortRow: null,
        updatingEntries: false,
      }
    },
    computed: {
      readOnly() {
        return this.params.readOnly
      },
      timeCardBatch() {
        return this.$store.state.payroll.currentTimecardBatch
      },
      canUpdateBatch() {
        return isAuthorized('authorizedToUpdate', this.timeCardBatch)
      },
      groupRowRendererParams() {
        return {

        }
      },
      extraGroupParams() {
        if (!this.readOnly) {
          return {}
        }
        return {
          groupRowRendererParams: {
            innerRenderer: 'TimecardEntryGroupRow',
            suppressCount: true,
            groupRowRendererParams: {
              days: this.params.days,
            },
          },
          groupDefaultExpanded: -1,
          groupIncludeFooter: true,
          groupDisplayType: 'groupRows'
        }
      },
      columns() {
        return [
          {
            headerName: this.$t('Day'),
            field: 'date',
            minWidth: this.readOnly ? 150 : 120,
            maxWidth: this.readOnly ? 120 : 180,
            cellEditor: cellEditors.BaseSelect,
            cellEditorParams: {
              options: this.params.days,
            },
            required: true,
            editable: this.canEditEntry,
            cellClass: this.editCellClass,
            valueSetter: requiredValueSetter,
            rowGroup: this.readOnly,
            hide: this.readOnly,
          },
          {
            ...costCenterCol,
            valueSetter: params => {
              params.data.cost_center = params.newValue
              params.data = getCostCenterRowDefaults(params.data, this.params.data.employee)
              params.node.setData(params.data)
              return true
            },
            editable: this.canEditEntry,
            cellClass: this.editCellClass,
          },
          {
            ...sourceCol,
            editable: params => {
              return sourceCol.editable(params) && this.canEditEntry(params)
            },
            cellClass: params => {
              if (!this.canEditEntry(params)) {
                return cellClasses.ReadOnly
              }
              return sourceCol.cellClass(params)
            },
          },
          {
            ...typeCol,
            editable: params => {
              return typeCol.editable(params) && this.canEditEntry(params)
            },
            cellClass: params => {
              if (!this.canEditEntry(params)) {
                return cellClasses.ReadOnly
              }
              return typeCol.cellClass(params)
            },
          },
          {
            ...additionalSourceCol(),
            editable: params => {
              return additionalSourceCol().editable(params) && this.canEditEntry(params)
            },
            cellClass: params => {
              if (!this.canEditEntry(params)) {
                return cellClasses.ReadOnly
              }
              return additionalSourceCol().cellClass(params)
            },
          },
          {
            ...accountCol(this.readOnly),
            hide: true,
          },
          {
            field: 'regular_hours',
            headerName: 'Regular Hours',
            minWidth: 50,
            maxWidth: 150,
            cellEditor: cellEditors.Numeric,
            editable: true,
            // editable: this.canEditEntry,
            // cellClass: this.editCellClass,
            valueSetter: params => requiredValueSetter(params, 0),
            aggFunc: 'sum',
          },
          {
            field: 'overtime_hours',
            headerName: 'Overtime Hours',
            minWidth: 50,
            maxWidth: 150,
            cellEditor: cellEditors.Numeric,
            editable: true,
            // editable: this.canEditEntry,
            // cellClass: this.editCellClass,
            valueSetter: params => requiredValueSetter(params, 0),
            aggFunc: 'sum',
          },
          {
            field: 'premium_hours',
            headerName: 'Premium Hours',
            minWidth: 50,
            maxWidth: 150,
            cellEditor: cellEditors.Numeric,
            editable: true,
            // editable: this.canEditEntry,
            // cellClass: this.editCellClass,
            valueSetter: params => requiredValueSetter(params, 0),
            aggFunc: 'sum',
          },
          {
            field: 'special_source_type',
            headerName: 'Special Type',
            minWidth: 60,
            maxWidth: 150,
            editable: true,
            cellEditor: cellEditors.SpecialCodeType,
            valueGetter: params => {
              return getSpecialCodeTypeLabel(params.data?.special_source_type)
            },
            valueSetter: params => {
              params.data.special_source_id = ''
              params.data.units = 0
              params.data.special_source_type = params.newValue
              if (!params.newValue) {
                params.data.special_rate = 0
                params.data.special_account = null
              }
              this.tryComputeSpecialUnits(params)
              params.node.setData(params.data)
              if (params.newValue === codeTypes.TIME_OFF) {
                this.$store.dispatch('globalLists/getTimeOffStatuses', this.params.data?.employee_id)
              }
              return true
            },
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab';
              if (isTabKey && !params.data.special_source_type) {
                params.api.stopEditing();
              }
            }
          },
          {
            field: 'special_source_id',
            headerName: 'Special Code',
            minWidth: 120,
            maxWidth: 180,
            editable: params => {
              return !!params.data.special_source_type
            },
            suppressNavigable: params => {
              return !params.data.special_source_type
            },
            component: 'SpecialSourceLink',
            cellRendererParams: {
              target: '_blank',
            },
            cellEditor: cellEditors.SpecialCode,
            cellClass: params => {
              const hasValue = params.data?.special_source_id
              if (!hasValue && params.data?.special_source_type) {
                return cellClasses.Invalid
              }
              if (!params.data?.special_source_type) {
                return cellClasses.ReadOnly
              }
              return ''
            },
            valueSetter: params => {
              params.data.special_source_id = params.newValue
              const option = getSpecialSourceOption(params.data)

              if (params.data.special_source_type === codeTypes.EQUIPMENT) {
                params.data.special_rate = option?.standard_job_cost_hourly_rate || 0
              }
              if (params.data.special_source_type === codeTypes.MATERIAL) {
                params.data.special_rate = option?.standard_unit_rate || 0
              }
              if (!params.newValue) {
                params.data.special_account = null
              }
              return true
            },
          },
          {
            field: 'units',
            headerName: 'Units',
            minWidth: 50,
            maxWidth: 90,
            cellEditor: cellEditors.Numeric,
            editable: params => {
              const { special_source_type, special_source_id } = params.data || {}
              return special_source_type && special_source_id
            },
            cellClass: params => {
              const { special_source_type, special_source_id } = params.data || {}
              if (!special_source_type && !special_source_id) {
                return cellClasses.ReadOnly
              }
              return ''
            },
            valueSetter: params => {
              return requiredValueSetter(params, 0)
            },
            suppressNavigable: params => {
              return !params.data.special_source_type
            },
            suppressKeyboardEvent: stopEditingOnTab,
          },
          {
            headerName: ' ',
            field: 'overrides',
            pinned: 'right',
            minWidth: 40,
            maxWidth: 60,
            editable: true,
            cellEditor: 'TimeCardEntryOverridesDialog',
            hide: this.readOnly,
            cellEditorParams: {
              employee_id: this.params.data?.employee_id,
              saveOrUpdateEntry: this.saveOrUpdateEntry,
            },
            suppressPaste: true,
            cellEditorPopup: true,
            onCellClicked: params => {
              params.api.startEditingCell({
                rowIndex: params.rowIndex,
                colKey: params.column.colId,
              })
            }
          },
          {
            headerName: ' ',
            field: 'view_actions',
            pinned: 'right',
            minWidth: 40,
            maxWidth: 60,
            hide: !this.readOnly,
          },
          {
            headerName: ' ',
            field: 'row_actions',
            pinned: 'right',
            minWidth: 40,
            maxWidth: 60,
            cellEditor: 'TimeCardEntryDeleteDialog',
            cellEditorPopup: true,
            suppressPaste: true,
            hide: !this.canUpdateBatch,
            editable: this.canEditEntry,
            cellEditorParams: {
              onConfirm: (params) => {
                this.updateParentRow(params, 'remove')
              }
            },
            onCellClicked: params => {
              if (!this.canEditEntry(params)) {
                return
              }
              params.api.startEditingCell({
                rowIndex: params.rowIndex,
                colKey: params.column.colId,
              })
            }
          },
        ]
      },
    },
    methods: {
      editCellClass(params) {
        if (!this.canEditEntry(params)) {
          return cellClasses.ReadOnly
        }
        return ''
      },
      canEditEntry(params) {
        return !params?.data?.timesheet_entry_id
      },
      getRowId(params) {
        return params.data._localId || params.data.id
      },
      initData() {
        this.data = this.params?.data?.entries || []
      },
      onCellFocused(params) {
        updateCostCenterHeaderNames(params)
        const rowData = params.api.getDisplayedRowAtIndex(params.rowIndex)
        const row = rowData?.data
        if (row) {
          this.closeOtherSummaries()
          this.lastSelectedRow = row
          this.lastSelectedShortRow = row
        }
      },
      viewSummary(row) {
        if (!row) {
          return
        }
        this.closeOtherSummaries()
        this.lastSelectedRow = row
        this.lastSelectedShortRow = row
      },
      closeOtherSummaries() {
        bus.$emit('close-timecard-entry-summary')
      },
      handleBusEvents() {
        bus.$on('close-timecard-entry-summary', () => {
          this.lastSelectedRow = null
          this.lastSelectedShortRow = null
        })
      },
      async onCellValueChanged(params) {
        const field = params?.colDef?.field

        if (hourFields.includes(field)) {
          this.updateParentRow(params, 'update')
          this.tryComputeSpecialUnits(params)
        }

        if (!costCenterFields.includes(field)) {
          await this.saveOrUpdateEntry(params)
          return
        }

        await this.saveOrUpdateEntry(params)
      },
      tryComputeSpecialUnits(params) {
        if (params?.data?.special_source_type === codeTypes.EQUIPMENT) {
          const { regular_hours, overtime_hours, premium_hours } = params.data
          params.data.units = +regular_hours + +overtime_hours + +premium_hours
        }
      },
      onGridReady(params) {
        this.grid = params
      },
      getTableData() {
        const data = []
        this.grid.api.forEachNode(node => {
          data.push(node.data)
        })
        return data
      },
      getTableNodes() {
        const nodes = []
        this.grid.api.forEachNode(node => {
          nodes.push(node)
        })
        return nodes
      },
      async onAddRow(params, createdRow) {
        this.updateParentRow(createdRow, 'add')
        if (createdRow) {
          await this.saveOrUpdateEntry(params)
        }
      },
      getEmptyRow() {
        const entries = this.getTableData()
        let previousRow = entries[entries.length - 1]
        return getEmptyEntry({
          order: entries.length - 1,
          employee: this.params.data.employee,
          days: this.params.days,
          previousRow,
        })
      },
      async updateAllEntries() {
        try {
          this.updatingEntries = true
          const nodes = this.getTableNodes()
          for (let node of nodes) {
            await this.saveOrUpdateEntry({ node, data: node.data })
          }
        } finally {
          this.updatingEntries = false
        }
      },
      mapEntryToRequest(entry) {
        const defaultEntry = getEmptyEntry({
          employee: this.params.data.employee,
          days: this.params.days,
        })
        let mappedEntry = setTypeSources(entry)
        mappedEntry = cloneDeep(entry)

        for (let key in defaultEntry) {
          mappedEntry[key] = entry[key]
        }
        mappedEntry.timecard_id = entry.timecard_id
        mappedEntry.date = entry.date
        if (entry.id) {
          mappedEntry.id = entry.id
          mappedEntry.date = this.$formatDate(entry.date, dateTypes.IsoDate, true)
        } else {
          mappedEntry.date = this.$formatDate(new Date(entry.date), dateTypes.IsoDate)
        }
        if (!mappedEntry.timecard_id) {
          mappedEntry.timecard_id = this.params.data.id
        }
        if (!mappedEntry.certified_payroll_exempt_from) {
          mappedEntry.certified_payroll_exempt_from = RateFrom.Undefined
        }
        if (!mappedEntry.sub_trade_from) {
          mappedEntry.sub_trade_from = RateFrom.Undefined
        }
        return mappedEntry
      },
      async updateEntry(row, params) {
        const { data } = await axios.put(`/restify/timecard-entries/${row.id}`, row)
        const rowData = this.mergeRowData(params.data, data)
        params.data = rowData
        params.node.setData(rowData)
        return rowData
      },
      async saveEntry(row, params) {
        if (params.data.creating) {
          // We are in the process of creating this row already, therefore delaying the save
          return params.data
        }
        let rowData = params.data
        try {
          params.data.creating = true
          params.node.setData(params.data)
          delete row.id
          const { data } = await axios.post(`/restify/timecard-entries`, row)
          rowData = this.mergeRowData(params.data, data)
          const prevData = params.data
          params.data = rowData
          params.data._localId = prevData._localId
          params.node.setData(params.data)
        } finally {
          params.data.creating = false
          params.node.setData(params.data)
        }
        return rowData
      },
      mergeRowData(localData, apiData) {
        return {
          ...localData,
          ...(apiData?.attributes || {})
        }
      },
      async saveOrUpdateEntry(params) {
        const row = params.data
        const isValid = isRowValid(row)
        if (!isValid) {
          return params.data
        }
        const entry = this.mapEntryToRequest(row)
        let savedData = params.data
        try {
          params.data.loading = true
          if (!entry.id) {
            savedData = await this.saveEntry(entry, params)
          } else {
            savedData = await this.updateEntry(entry, params)
          }
          await this.$store.dispatch('payroll/resetTimecardBatchToPending')
          this.lastSelectedRow = savedData
          this.lastSelectedShortRow = savedData
        } finally {
          params.node.setData(params.data)
        }
        return savedData
      },
      updateParentRow(params, transaction = 'add') {
        const entries = this.getTableData()
        let totals = getTotals(entries)
        let entries_count = this.params.data.entries_count || 0


        if (transaction === 'add') {
          entries_count++
        } else if (transaction === 'remove') {
          entries_count--
        }

        const node = this.params.node
        this.params.data.entries = entries

        node.setDataValue('totals.regular_hours', totals.regular_hours)
        node.setDataValue('totals.premium_hours', totals.premium_hours)
        node.setDataValue('totals.overtime_hours', totals.overtime_hours)
        node.setDataValue('totals.total_hours', totals.total_hours)
        node.setDataValue('entries_count', entries_count)
      },
      async resetFromFields() {
        const confirmed = await this.$confirm({
          title: this.$t('Are you sure?'),
          description: this.$t('This will reset time card entry fields such as rates, accounts, state etc to their default values.'),
          buttonText: this.$t('Reset fields'),
        })
        if (!confirmed) {
          return
        }

        // TODO - reset fields when backend is implemented
        // /restify/timecards/:id/actions?action=reset-overrides - POST
        // Refresh the table afterwards
      }
    },
    created() {
      this.initData()
      this.handleBusEvents()
    }
  }
</script>
